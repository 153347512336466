import { VoidComponent, createSignal, lazy } from "solid-js";
import { ContestsService } from "../../../gen/webapi/v1/contests_connect";
import { queryClient, useClient } from "../../api-client";
import LiveContestsSection from "../contest/LiveContestsSection";
import type { UserContestResult } from "../../../gen/webapi/v1/contests_pb";
import { ModalLoader } from "../ui/ModalLoader";
import { QueryClientProvider } from "@tanstack/solid-query";

const JoinContestModal = lazy(() => import("../purchase/JoinContestModal"));
const client = useClient(ContestsService);

const LiveContests: VoidComponent = () => {
    const querier = async () => {
        const s = await client.listLiveContests({});
        return s.liveContests;
    }

    const [showJoinModal, setShowJoinModal] = createSignal(false)
    const [selectedContest, setSelectedContest] = createSignal<UserContestResult>()
    const initiateBuy = (uc: UserContestResult) => {
        setSelectedContest(uc)
        setShowJoinModal(true)
    }

    return <>
        <QueryClientProvider client={queryClient}>
            <LiveContestsSection querier={querier}
                buyTickets={initiateBuy}
                selectedContest={selectedContest}
                setSelectedContest={setSelectedContest} />

        </QueryClientProvider>


        <ModalLoader
            component={JoinContestModal}
            show={showJoinModal}
            hide={() => setShowJoinModal(false)}
            userContest={selectedContest()}
            onBuyComplete={() => {
                queryClient.refetchQueries({ queryKey: ["liveContests"] })
                setShowJoinModal(false)
                setSelectedContest()
            }}
        />
    </>
}

export default LiveContests;